window.CropperOverlay  = class CropperOverlay {
  constructor(imageUrl) {
    const image = new Image();
    image.src = imageUrl;
    image.crossOrigin = 'true';

    this.overlay = document.getElementById('featured_image_square_transform');
    this.editor = document.createElement('div');
    this.confirmButton = document.createElement('button');
    this.cancelButton = document.createElement('button');
    this.confirmButton.textContent = I18n.t('buttons.ok');
    this.confirmButton.classList.add('button', 'button--primary');
    this.confirmButton.dataset.turbolinks = 'false';
    this.cancelButton.textContent = I18n.t('buttons.cancel');
    this.cancelButton.classList.add('button');
    this.editor.className = 'featured-image-square-transform-editor';
    this.editor.appendChild(image);
    this.editor.appendChild(this.confirmButton);
    this.editor.appendChild(this.cancelButton);
    this.overlay.classList.add('is-visible');
    this.overlay.appendChild(this.editor);

    this.cropper = new Cropper(image, {
      aspectRatio:      1,
      viewMode:         1,
      checkCrossOrigin: true,
      checkOrientation: false,
    });
  }

  getCroppedCanvas(options) {
    return this.cropper.getCroppedCanvas(options);
  }

  addConfirmListener(eventName, callback) {
    return this.confirmButton.addEventListener(eventName, (event) => {
      this.confirmButton.disabled = true;
      callback(event);
    });
  }

  addCancelListener(eventName, callback) {
    return this.cancelButton.addEventListener(eventName, callback);
  }

  clear() {
    this.overlay.removeChild(this.editor);
    this.overlay.classList.remove('is-visible');
  }
}
