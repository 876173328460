/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascripts and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import '../stylesheets/application'

import ujs from "@rails/ujs"
ujs.start();

import turbolinks from "turbolinks"
turbolinks.start();

import { i18n } from "i18n/i18n-loader";
window.I18n = i18n;

import 'bootstrap';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/sortable';
import 'select2/dist/js/select2.full';
import 'select2/dist/js/i18n/en';
import 'select2/dist/js/i18n/de';
import 'select2/dist/js/i18n/fr';


import Cropper from 'cropper.min';
window.Cropper = Cropper;

window.Dropzone = require("dropzone");
require('global/mpp_dropzone');

require('script-loader!pickadate/lib/legacy');
require('script-loader!pickadate/lib/picker');
require('script-loader!pickadate/lib/picker.date');
require('script-loader!pickadate/lib/picker.time');
if (window.I18n.locale == 'de') require('script-loader!pickadate/lib/translations/de_DE');
if (window.I18n.locale == 'fr') require('script-loader!pickadate/lib/translations/fr_FR');

import 'partner/partner';
import 'partner/scroll_to_section';
import 'partner/set_shop_status';
import 'partner/credit_notes'
import 'partner/account'

import 'products/add_new_text_field';
import 'products/enable_printing';
import 'products/pickadate';
import 'products/products';
import 'products/sortable';
import 'products/select2';

import 'sold_items/export';
import 'sold_items/sold_items';

import 'overview/filter';

import 'global/clear_filter';
import 'global/copy_to_clipboard';
import 'global/cropper_overlay';
import 'global/date_time_picker';
import 'global/global';
import 'global/select_dropdowns';
import 'global/sidebar_mobile';
import 'global/spinner_table';
import 'global/square_transformer';
import 'global/table_pagination';

require.context('../images', true);
import "controllers"
